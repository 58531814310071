import './App.css';
import Signin from './Components/Login/Login';
import { Home } from './Components/Home/Home';
import { useState, useEffect  } from 'react';
import { jwtDecode } from "jwt-decode";
import Toast from "./Components/Toastr"
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthorizedEmail = ["xmgpollogamer@gmail.com","dragodlxe@gmail.com","jdsv.6779@gmail.com","valr060998@gmail.com"];

function App() {
  
  const[user,setUser]=useState("")
  
  let userToken= {};
  if(user !== "")
  {
    try {
      userToken = jwtDecode(user);
    } catch (error) {
      console.error("Invalid JWT token:", error);
    }
  }
  useEffect(() => {
    if (user) {
      if (!AuthorizedEmail.includes(userToken.email)) {
        toast.error('Unauthorized email address!');
      } else {
        toast.success('Welcome!');
      }
    }
  }, [user, userToken.email]);

  const trackableSetUser = (newUser) => {
    setUser(newUser);
  };

  return (
    <div className="App">
      {
        !AuthorizedEmail.includes(userToken.email)
          ? <Signin setUser={trackableSetUser} /> 
          : <Home user={userToken} />
      }
      <Toast />
    </div>

  );
}

export default App;
