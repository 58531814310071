import React,{useState} from 'react';
import "./Login.css"
import { GoogleLogin } from '@react-oauth/google';


function Signin({setUser}){
    const [username,setUsername] = useState("")
    const[password,setPassword] = useState("")
    
    const handleSubmit = (e)=>{
        e.preventDefault()
    }

    return(
    <div className="Auth-form-container">
        <form 
            className="Auth-form"
            onSubmit={handleSubmit}
        >
            <div className="Auth-form-content">
                <h3 className="Auth-form-title">Sign In</h3>
                <div className="form-group mt-3">
                    <label>Email address</label>
                    <input
                    disabled
                    type="email"
                    value={username}
                    className="form-control mt-1"
                    placeholder="email"
                    onChange={e=> setUsername(e.target.value)}
                    />

                </div>
                <div className="form-group mt-3">
                    <label>Password</label>
                    <input
                    disabled
                    type="password"
                    value={password}
                    className="form-control mt-1"
                    placeholder="password"
                    onChange={e=> setPassword(e.target.value)}
                    />
                </div>
                <div className="d-grid gap-2 mt-3">
                    <button disabled type="submit" className="btn btn-primary">
                    Sign In
                    </button>
                </div>          
            </div>
            <div className="form-group mt-3">
                <label>Login Google</label>
                <div className='logingoogle'>
                    <GoogleLogin
                        className="signInButton"
                        onSuccess={credentialResponse => {
                            setUser(credentialResponse.credential)                                                   
                        }}
                        onError={() => {
                        }}
                    />                
                </div>                
            </div>            
        </form>
    </div>        
    )
} 

export default Signin;