import React from 'react';
import { useState} from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import  Toast from './Toastr';
import { toast } from 'react-toastify';


function Uploadfile({setXml}){


    const[files,setFiles]=useState({})
    const [isLoading, setLoading] = useState(false);

    function handleChange(event) {
        setFiles(event.target.files)
    }
    const handleClick = () => {
        setLoading(true);
        if(files[0] === undefined)
        {
            toast.error('Seleccione algun XML');
            setLoading(false);
            return ;
        }
        toast.success(files.length +' archivos cargados con éxitos');
        setXml(files);

        setLoading(false);
        return;
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        e.target.reset();
      }
    return (
        <div style={{display:'flex', justifyContent:'center', marginTop:'5rem'}}>
            <Card style={{width:"80%"}}>
                <Card.Header>Seleccione los XML</Card.Header>
                <Card.Body>
                    <Form id='frmfiles'  onSubmit={handleSubmit}>
                        <Form.Group controlId="formFileMultiple" className="mb-3" as={Row}>
                            <Col sm="10">
                                <Form.Control
                                type="file"
                                onChange={handleChange}
                                multiple
                                accept=".xml"
                                />

                            </Col>
                            
                            <Col sm="2">
                                <Button 
                                    variant="outline-primary"
                                    disabled={isLoading}
                                    onClick={
                                        !isLoading ? handleClick : null
                                        
                                    }
                                >
                                    {isLoading ? 'Cargando…' : 'Cargar xml'}
                                </Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            <Toast />
        </div>
    );
}
export default Uploadfile;
