import NavbarProfile from "../Navbar";
import { XmlLoad } from "../XMLload";


export const Home = ({user}) => 
{
    let name = user.name;
    let email = user.email;
    let image= user.picture;

   
    return(        
        <div>
            <NavbarProfile img={image} email={email} name={name}/>
            <XmlLoad />            
        </div>
    )
}