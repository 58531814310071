import { Xml } from "./Data";

const _files =[];

export function ProcessData(xmlfiles){
    var templist=[];
    var  parser, xmlDoc;
    let counter = 0;
    Array.from(xmlfiles).forEach(e =>{
        counter = counter + 1;
        const reader = new FileReader();
        reader.readAsText(e);
        reader.onload = () =>{
            let xmltosave = new Xml();
            parser = new DOMParser();
            xmlDoc = parser.parseFromString(reader.result,"text/xml");
            
            const nodes =  xmlDoc.getElementsByTagName('cfdi:Comprobante')[0].childNodes;
            var impuestos = null;
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];                    
                if (node.nodeName === "cfdi:Impuestos") {
                    impuestos = node;
                    break;                        
                }                    
            }
            
            if(impuestos!== null)
            {
                var Traslados = impuestos.getElementsByTagName("cfdi:Traslados")[0];
                xmltosave.id= xmlDoc.getElementsByTagName('tfd:TimbreFiscalDigital')[0].getAttribute("UUID");
                xmltosave.Total=impuestos.getAttribute('TotalImpuestosTrasladados');

                if(reader.result.includes("Exento"))
                {
                    xmltosave.BaseExcento=Traslados.getElementsByTagName("cfdi:Traslado")[0].getAttribute("Base");
                    templist.push(xmltosave);
                    return
                }

                for(let i=0;i < Traslados.getElementsByTagName("cfdi:Traslado").length;i++)
                {
                    switch(Traslados.getElementsByTagName("cfdi:Traslado")[i].getAttribute("TasaOCuota"))
                    {
                        case "0.000000":
                            xmltosave.Base00= Traslados.getElementsByTagName("cfdi:Traslado")[i].getAttribute("Base");
                            xmltosave.total00= Traslados.getElementsByTagName("cfdi:Traslado")[i].getAttribute("Importe");
                        break;
                        case "0.080000":
                            xmltosave.Base08= Traslados.getElementsByTagName("cfdi:Traslado")[i].getAttribute("Base");
                            xmltosave.total08= Traslados.getElementsByTagName("cfdi:Traslado")[i].getAttribute("Importe");
                        break;
                        case "0.160000":
                            xmltosave.Base16= Traslados.getElementsByTagName("cfdi:Traslado")[i].getAttribute("Base");
                            xmltosave.total16= Traslados.getElementsByTagName("cfdi:Traslado")[i].getAttribute("Importe");
                        break;
                        default:
                        break;
                    }
                }
                _files.push(xmltosave);
            }
            else{
                counter = counter - 1;
            }
        }
        reader.onerror=()=>
        {
            console.log(reader.error)   
        }
    });    
}

export function getFiles(){
    console.log(_files)
    return _files;
}