import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    { field: 'id', headerName: 'UUID', minWidth: 130, width:310 },
    { field: 'Total', headerName: 'Total de impuestos', type: 'number', minWidth: 130 },
    { field: 'Base16', headerName: 'Base Tasa 16', type: 'number', width: 120 },
    { field: 'total16', headerName: 'Importe 16', type: 'number', width: 120, },
    { field: 'Base08', headerName: 'Base Tasa 08', type: 'number', width: 120 },
    { field: 'total08', headerName: 'Importe 08', type: 'number', width: 120, },
    { field: 'Base00', headerName: 'Base Tasa 00', type: 'number', width: 120 },
    { field: 'total00', headerName: 'Importe 00', type: 'number', width: 120, },
    { field: 'BaseExento', headerName: 'Base Excento', type: 'number', width: 120 },
  ];

export default function DataTableXml({ListofXml}) {
  return (
    <div style={{ height: "max-content", display:"flex", justifyContent:"center",width:"80%"}}>
      <DataGrid
        style={{}}
        rows={ListofXml}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10,15,20]}
        checkboxSelection
      />
    </div>
  );
}