export function Xml(id, Total, Base16, total16, Base08, total08, Base00, total00,BaseExcento) {    
    this.id = id;
    this.Total = Total;
    this.Base16=Base16;
    this.total16=total16;
    this.Base08=Base08;
    this.total08=total08;
    this.Base00=Base00;
    this.total00=total00;
    this.BaseExcento=BaseExcento;    
  }