import Uploadfile from './UploadFile'
import DataTableXml from './DatatableXML'
import Row from 'react-bootstrap/esm/Row';
import Card from 'react-bootstrap/Card';

import { useState,useEffect } from 'react';
import { ProcessData,getFiles } from '../Helpers/ProcessData';
import { Button } from '@mui/material';

export function XmlLoad(){
    const [xmlfiles,setXml] = useState({});
    const [ListofXml,SetListofxml] = useState([]);
    const [filesload,setFilesload]= useState(false);
    const [totalfactutas, setTotalfacturas] = useState();

    useEffect(()=>{
        ProcessData(xmlfiles);
        
    },[xmlfiles]);
    function SetFiles(){
        SetListofxml();
        let Files = getFiles();
        let total = Files.reduce((partialsum,a)=> partialsum + parseFloat(a.Total),0);
        setTotalfacturas(total)
        setFilesload(true)
        SetListofxml(Files)
    }
    return(
        <div>
            <Row style={{display:'flex',justifyContent:'center'}}>
                <Uploadfile setXml={setXml} />
            </Row>
            <Row>
                <Button onClick={SetFiles}>
                    Cargar información
                </Button>
            </Row>
            <Row style={{display:'flex',justifyContent:'center', marginTop:'5rem'}}>
                <DataTableXml ListofXml={ListofXml}/>
            </Row>
            {
                filesload &&
                <Row style={{display:'flex',justifyContent:'center', marginTop:'5rem'}}>
                    <Card style={{width:"80%"}}>
                        <Card.Header>Total de facturas: ${totalfactutas}</Card.Header>                        
                    </Card>
                </Row>
            }
            

        </div>
        
    )
}